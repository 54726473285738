import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Typography } from "../typography";

export type ToggleProps = {
  name: string;
  className?: string;
  checked?: boolean;
  alwaysChecked?: boolean;
  leftText?: string;
  rightText?: string;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  inactiveStyle?: boolean;
  blueStyle?: boolean;
  stopClickPropagation?: boolean;
};

export const Toggle = (props: ToggleProps) => {
  const {
    name,
    className,
    checked = false,
    disabled = false,
    rightText,
    leftText,
    onChange,
    inactiveStyle = false,
    blueStyle = false,
    stopClickPropagation = true,
  } = props;

  const handleChange = () => {
    if (!disabled) {
      onChange?.(!checked);
    }
  };

  return (
    <label
      onClick={(e) => {
        e.preventDefault();
        if (stopClickPropagation) e.stopPropagation();
        handleChange();
      }}
      className={cn(
        "inline-flex items-center ",
        {
          "cursor-pointer": !disabled,
        },
        className,
      )}
    >
      {leftText && (
        <Typography
          className={cn("text-dark-2 mr-2", {
            "text-accent": blueStyle && !checked,
          })}
          variant="body2"
        >
          {leftText}
        </Typography>
      )}
      <div className="relative inline-flex">
        <input
          onChange={handleChange}
          checked={checked}
          name={name}
          type="checkbox"
          disabled={disabled}
          className="peer sr-only"
        />
        <div
          className={cn(
            "peer-checked:after:bg-primary-500 peer-checked:bg-primary-200 peer h-[28px] w-[52px] rounded-full bg-neutral-300 after:absolute after:left-[5px] after:top-[4px] after:h-5 after:w-5 after:rounded-full after:bg-white after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-focus:outline-none",
            {
              "bg-light-1 after:bg-white peer-checked:after:bg-white": disabled,
            },
            { "bg-accent-medium after:bg-accent": blueStyle },
            {
              "bg-dark-3 peer-checked:bg-dark-3 after:bg-white peer-checked:after:bg-white":
                inactiveStyle,
            },
          )}
        />
      </div>
      {rightText && (
        <Typography
          className={cn("text-dark-2 ml-2", checked && "font-semibold", {
            "text-accent":
              (checked && !inactiveStyle) || (blueStyle && checked),
          })}
          variant="body2"
        >
          {rightText}
        </Typography>
      )}
    </label>
  );
};
