import {
  CreateDebtorRepaymentRequestBody,
  CreditorRepayment,
  DebtorRepayment,
  Investment,
  RepaymentPeriod,
  User,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const repaymentsEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getUserRepayments
   * @summary get user repayments by user id
   * @request GET:/api/v1/users/:userId/repayments
   * @secure
   */
  getUserRepayments: (
    userId: User["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<CreditorRepayment[]>, void>({
      path: `/api/v1/users/${userId}/repayments`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name getProjectDebtorRepaymentsPeriods
   * @summary get debtor repayments periods by project id
   * @request GET:/api/v1/repayments/debtors/:projectId/periods
   * @secure
   */
  getProjectDebtorRepaymentsPeriods: (
    projectId: string,
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<RepaymentPeriod[]>, void>({
      path: `/api/v1/repayments/debtors/${projectId}/periods`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getCreditorsRepaymentsForDebtorRepayment: (
    debtorRepaymentId: DebtorRepayment["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<CreditorRepayment[]>, void>({
      path: `/api/v1/repayments/debtors/${debtorRepaymentId}/creditors-repayments`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getCreditorsRepaymentsPeriodsForInvestment: (
    investmentId: Investment["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<CreditorRepayment[]>, void>({
      path: `/api/v1/repayments/creditors/${investmentId}/periods`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  createDebtorRepayment: (
    body: CreateDebtorRepaymentRequestBody,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<DebtorRepayment>, void>({
      path: `/api/v1/repayments/debtors`,
      method: "POST",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  deleteDebtorRepayment: (
    debtorRepaymentId: DebtorRepayment["id"],
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<DebtorRepayment>, void>({
      path: `/api/v1/repayments/debtors/${debtorRepaymentId}`,
      method: "DELETE",
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  approveDebtorRepayment: (
    debtorRepaymentId: DebtorRepayment["id"],
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<DebtorRepayment>, void>({
      path: `/api/v1/repayments/debtors/${debtorRepaymentId}/approve`,
      method: "PATCH",
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  dunningAllRepayment: (
    debtorRepaymentId: DebtorRepayment["id"],
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<null>, void>({
      path: `/api/v1/repayments/debtors/${debtorRepaymentId}/dunning/all`,
      method: "POST",
      secure: true,
      ...params,
    }),
});
