import { z } from "zod";

export const formSchema = z.object({
  shouldSendReminderMail: z.boolean(),
});

export type ReminderFormValues = z.infer<typeof formSchema>;

export const defaultReminderFormValues: ReminderFormValues = {
  shouldSendReminderMail: false,
};
