import { z } from "zod";

const formSchema = z.object({
  shouldSendPublicationMail: z.boolean().optional().default(true),
  shouldSendPublicationNotification: z.boolean().optional().default(true),
});
type PublishFormValues = z.infer<typeof formSchema>;

const defaultPublishFormValues: PublishFormValues = {
  shouldSendPublicationMail: true,
  shouldSendPublicationNotification: true,
};

export { formSchema, defaultPublishFormValues, type PublishFormValues };
