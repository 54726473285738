const setCookie = (name: string, value: string, days: number): void => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  const domain = window.location.hostname.split(".").slice(-2).join("."); // Get the root domain
  document.cookie = `${name}=${encodeURIComponent(
    value,
  )};expires=${expires.toUTCString()};path=/;domain=.${domain}`;
};

const getCookie = (name: string): string | null => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const c = cookies[i].trim();
    if (c.indexOf(nameEQ) === 0) {
      return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
  }

  return null;
};

export function processUtms(): void {
  function getQueryParam(url: string, param: string): string {
    param = param.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regexS = `[?&]${param}=([^&#]*)`;
    const regex = new RegExp(regexS);
    const results = regex.exec(url);

    if (
      results === null ||
      (results && typeof results[1] !== "string" && (results[1] as any).length)
    ) {
      return "";
    } else {
      return decodeURIComponent(results[1]).replace(/\W/gi, " ");
    }
  }

  // Helper function to extract campaign parameters
  function campaignParams(): Record<string, string> {
    const campaignKeywords = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_content",
      "utm_term",
      "utm_id",
      "fbclid",
      "gclid",
    ];
    const utmParams: Record<string, string> = {};

    for (const keyword of campaignKeywords) {
      const value = getQueryParam(document.URL, keyword);
      if (value.length) {
        utmParams[keyword] = value;
      }
    }

    return utmParams;
  }

  // Get UTM values
  const utmValues = campaignParams();

  // Retrieve existing cookie
  const existingCookie = getCookie("utm_data");
  const utmData: {
    first_utm?: Record<string, string>;
    last_utm?: Record<string, string>;
    first_referrer?: string;
    last_referrer?: string;
  } = existingCookie ? JSON.parse(existingCookie) : {};

  // Update First UTMs (only if not already set)
  if (!utmData.first_utm && Object.keys(utmValues).length > 0) {
    utmData.first_utm = utmValues;
  }

  if (!utmData.first_referrer && document.referrer) {
    utmData.first_referrer = document.referrer;
  }

  // Update Last UTMs (overwrite every time)
  if (Object.keys(utmValues).length > 0) {
    utmData.last_utm = utmValues;
  }

  if (document.referrer) {
    utmData.last_referrer = document.referrer;
  }
  // Store the updated data back into the cookie
  setCookie("utm_data", JSON.stringify(utmData), 365); // Store for 1 year
}
