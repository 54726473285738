import { useEffect } from "react";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

export const CHATBOT_SCRIPT_URL =
  "https://convia.io/noname/v1/noname.js?key=0192dd63-8367-7930-bfda-0d5c9323e3cd";

export const useInitConvia = () => {
  const { isAdmin } = useUserBusiness();
  const isProd = import.meta.env["VITE_ENV"] === "prod";
  const shouldUseConvia = isAdmin && !isProd;

  useEffect(() => {
    if (!shouldUseConvia) return;

    const scriptId = "convia-chatbot-script";

    const loadScript = () => {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = CHATBOT_SCRIPT_URL;
      script.defer = true;
      script.onload = () => console.log("Convia script loaded successfully");
      script.onerror = () => console.error("Failed to load Convia script");
      document.body.appendChild(script);
    };

    if (!document.getElementById(scriptId)) {
      loadScript();
    }

    return () => {
      const script = document.getElementById(scriptId);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [shouldUseConvia]);

  return null;
};
