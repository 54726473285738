import * as React from "react";
import PrimitiveCurrencyInput, {
  CurrencyInputProps as PrimitiveCurrencyInputProps,
} from "react-currency-input-field";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

type CurrencyInputV2Props = Omit<PrimitiveCurrencyInputProps, "onChange">;

const CurrencyInputV2 = React.forwardRef<
  HTMLInputElement,
  CurrencyInputV2Props
>(
  (
    {
      className,
      intlConfig = { locale: "fr-FR", currency: "EUR" },
      decimalsLimit = 2,
      placeholder = "Enter a number",
      value,
      onValueChange,
      ...props
    },
    ref,
  ) => {
    return (
      <PrimitiveCurrencyInput
        {...props}
        ref={ref}
        intlConfig={intlConfig}
        decimalsLimit={decimalsLimit}
        placeholder={placeholder}
        onChange={undefined}
        value={value}
        onValueChange={onValueChange}
        className={cn(
          `   border-input bg-background ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring
              disabled:bg-light-1 disabled:text-dark-4 flex
              h-10 w-full
              rounded-md border px-3 py-2 
              text-sm file:border-0 file:bg-transparent
              file:text-sm file:font-medium
              focus-visible:outline-none focus-visible:ring-2
              focus-visible:ring-offset-2
              disabled:cursor-not-allowed
              `,
          className,
        )}
      />
    );
  },
);

export { type CurrencyInputV2Props, CurrencyInputV2 };
