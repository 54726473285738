import {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  type ComponentPropsWithoutRef,
  type ElementRef,
  type PropsWithChildren,
  type ReactNode,
} from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ListChecksIcon,
  Trash2Icon,
} from "lucide-react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { z } from "zod";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../ui/accordion";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog/dialog";
import { CheckboxInput } from "../../../ui/form/inputs/checkbox-input/checkbox-input";
import { CurrencyInputConnected } from "../../../ui/form/inputs/currency-input/currency-input-connected";
import { RebrandBadge } from "../../../ui/rebrand-badge";
import { ButtonRebrand } from "../../ui/button/button-rebrand";
import { useComparator } from "./comparator.context";

const ComparatorTrigger = forwardRef<
  ElementRef<typeof ButtonRebrand>,
  ComponentPropsWithoutRef<typeof ButtonRebrand>
>(({ children, className, onClick, ...props }, ref) => {
  const { mode, selectedCount, config } = useComparator();

  return (
    <ButtonRebrand
      ref={ref}
      onClick={onClick}
      className={cn(
        "font-roobert flex items-center text-sm font-medium transition-all",
        className,
      )}
      {...props}
    >
      <span>
        Comparer{" "}
        {mode === "selection" && `${selectedCount}/${config.maxSelection}`}
      </span>
      <ListChecksIcon className=" ml-2.5 h-5 w-5" />
    </ButtonRebrand>
  );
});

const ComparatorSelectionTrigger = forwardRef<
  ElementRef<typeof ButtonRebrand>,
  ComponentPropsWithoutRef<typeof ButtonRebrand>
>(({ children, className, onClick, ...props }, ref) => {
  const { mode, setMode } = useComparator();

  return (
    <ComparatorTrigger
      ref={ref}
      {...props}
      variant="secondary"
      onClick={() => setMode("selection")}
      className={cn(
        mode === "selection" &&
          "bg-accent-super-light-rebrand ring-accent text-accent [&_svg]:text-accent ring-[3px]",
        className,
      )}
    />
  );
});

const ComparatorModalTrigger = forwardRef<
  ElementRef<typeof ButtonRebrand>,
  ComponentPropsWithoutRef<typeof ButtonRebrand>
>(({ children, className, ...props }, ref) => {
  const { mode, selectedCount, config, setMode, setSimulatorAmount } =
    useComparator();

  const handleOpenModal = () => {
    setMode("comparison");
    setSimulatorAmount(config.defaultSimulatorAmount);
  };

  return (
    <ComparatorTrigger
      ref={ref}
      {...props}
      variant="secondary"
      disabled={selectedCount < config.minSelection}
      onClick={handleOpenModal}
      className={cn(
        mode === "selection" &&
          "bg-accent sm:bg-accent-super-light-rebrand sm:ring-accent sm:text-accent sm:[&_svg]:text-accent text-white sm:ring-[3px]",
        className,
      )}
    />
  );
});

const ComparatorClear = forwardRef<
  ElementRef<typeof ButtonRebrand>,
  ComponentPropsWithoutRef<typeof ButtonRebrand>
>(({ children, className, ...props }, ref) => {
  const { mode, setMode, setSelection } = useComparator();

  const handleClear = () => {
    setMode(null);
    setSelection([]);
  };

  if (mode !== "selection") {
    return null;
  }

  return (
    <ButtonRebrand
      ref={ref}
      variant="ghost"
      onClick={handleClear}
      className={cn(
        "font-roobert text-sm font-medium text-black underline",
        className,
      )}
      {...props}
    >
      Annuler
    </ButtonRebrand>
  );
});

const ComparatorSelectableItem = <T,>({
  children,
  className,
  item,
  ...props
}: ComponentPropsWithoutRef<"div"> & { item: T }) => {
  const {
    mode,
    canSelect,
    config,
    addItem,
    removeItem,
    isSelected,
    getSearchParamsValue,
  } = useComparator<T>();

  const handleSelect = useCallback(
    (checked: boolean) => {
      if (checked) {
        addItem(item);
      } else {
        removeItem(item);
      }
    },
    [item, addItem, removeItem],
  );

  useEffect(() => {
    const selectionParams = getSearchParamsValue("selection");
    if (selectionParams?.length) {
      const itemParam = selectionParams.find(
        (param) => param === config.itemQueryParamFn(item),
      );
      if (itemParam && !isSelected(item)) {
        addItem(item);
      }
    }
  }, [
    item,
    getSearchParamsValue,
    config.itemQueryParamFn,
    isSelected,
    addItem,
  ]);

  if (mode !== "selection") {
    return children;
  }

  return (
    <div
      className={cn(
        "z-comparator-selectable-item relative rounded-lg ring-[6px] ring-transparent transition-all",
        isSelected(item) && "ring-accent",
        className,
      )}
      {...props}
    >
      {children}
      <CheckboxInput
        disabled={!isSelected(item) && !canSelect}
        checked={isSelected(item)}
        onCheckedChange={handleSelect}
        className="z-comparator-selectable-item-checkbox absolute right-4 top-4 h-6 w-6 bg-white"
      />
    </div>
  );
};

const ComparatorBanner = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  const { mode } = useComparator();

  if (mode === null) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cn(
        "z-comparator-banner flex flex-col border-t border-[#DFE2E8] bg-neutral-100 lg:py-6",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
});

const ComparatorBannerHeader = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"header">
>(({ children, className, ...props }, ref) => (
  <header
    ref={ref}
    className={cn(
      "flex flex-col gap-4 px-4 py-4 lg:flex-row lg:justify-between lg:py-0",
      className,
    )}
    {...props}
  >
    {children}
  </header>
));

const ComparatorBannerTitle = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  const { selectedCount } = useComparator();

  return (
    <div
      ref={ref}
      className={cn(
        "flex w-full items-center justify-between gap-2.5 lg:w-fit",
        className,
      )}
      {...props}
    >
      <p className="font-roobert text-lg font-semibold text-black">
        {children}
      </p>
      <RebrandBadge className="font-roobert h-6 w-6 whitespace-nowrap rounded-full border-neutral-200 bg-white text-xs font-medium text-neutral-900">
        {selectedCount}
      </RebrandBadge>
    </div>
  );
});

const ComparatorBannerDescription = forwardRef<
  HTMLParagraphElement,
  ComponentPropsWithoutRef<"p">
>(({ children, className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("font-roobert px-4 text-sm text-neutral-900", className)}
    {...props}
  >
    {children}
  </p>
));

const ComparatorSelectionScroller = ({
  children,
  className,
}: ComponentPropsWithoutRef<"div">) => {
  const scrollerRef = useRef<HTMLDivElement>(null);

  const { mode, selectedCount } = useComparator();

  const [canScrollTo, setCanScrollTo] = useState({
    left: false,
    right: false,
  });

  useEffect(() => {
    if (selectedCount === 0 || !scrollerRef?.current) {
      return;
    }

    const firstItemObserver = new IntersectionObserver(
      ([entry]) => {
        setCanScrollTo((prev) => ({
          ...prev,
          left: !entry.isIntersecting,
        }));
      },
      { threshold: 1 },
    );

    const lastItemObserver = new IntersectionObserver(
      ([entry]) => {
        setCanScrollTo((prev) => ({
          ...prev,
          right: !entry.isIntersecting,
        }));
      },
      { threshold: 1 },
    );

    if (scrollerRef.current.firstElementChild) {
      firstItemObserver.observe(scrollerRef.current.firstElementChild);
    }
    if (scrollerRef.current.lastElementChild) {
      lastItemObserver.observe(scrollerRef.current.lastElementChild);
    }

    return () => {
      firstItemObserver.disconnect();
      lastItemObserver.disconnect();
    };
  }, [selectedCount]);

  const handleScroll = useCallback(
    (direction: "left" | "right") => {
      if (!scrollerRef.current) {
        return;
      }

      const selectionListElement = scrollerRef.current.querySelector(
        "[data-selection-list]",
      );

      const LIST_GAP = window.innerWidth >= 1024 ? 32 : 16;
      const listScrollLeft = scrollerRef.current?.scrollLeft ?? 0;
      const listItemWidth =
        selectionListElement?.firstElementChild?.clientWidth ?? 0;

      const scrollLeft =
        direction === "left"
          ? listScrollLeft - listItemWidth - LIST_GAP
          : listScrollLeft + listItemWidth + LIST_GAP;

      scrollerRef.current?.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    },
    [scrollerRef],
  );

  if (!mode || selectedCount === 0) {
    return null;
  }

  return (
    <div
      className={cn("relative flex h-full w-full flex-col gap-4", className)}
    >
      {mode === "comparison" && (
        <header className="flex w-full flex-col items-center gap-4 border-neutral-200 lg:flex-row lg:justify-between lg:border-b lg:pb-8">
          <h4 className="font-roobert text-black-primary text-lg font-semibold">
            Comparatif
          </h4>
          <div className="flex items-center justify-center gap-x-4">
            <ButtonRebrand
              variant="secondary"
              className="h-14 w-14 rounded-full bg-white"
              onClick={() => handleScroll("left")}
              disabled={!canScrollTo.left}
            >
              <ChevronLeftIcon className="min-h-4 min-w-4 text-neutral-900" />
            </ButtonRebrand>
            <ButtonRebrand
              variant="secondary"
              className="h-14 w-14 rounded-full bg-white"
              onClick={() => handleScroll("right")}
              disabled={!canScrollTo.right}
            >
              <ChevronRightIcon className="min-h-4 min-w-4 text-neutral-900" />
            </ButtonRebrand>
          </div>
        </header>
      )}

      {mode === "selection" && canScrollTo.left && (
        <ButtonRebrand
          variant="secondary"
          className="absolute left-4 top-[calc(50%-16px)] z-10 h-8 w-8 rounded-full bg-white"
          onClick={() => handleScroll("left")}
        >
          <ChevronLeftIcon className="min-h-4 min-w-4 text-neutral-900" />
        </ButtonRebrand>
      )}
      {mode === "selection" && canScrollTo.right && (
        <ButtonRebrand
          variant="secondary"
          className="absolute right-4 top-[calc(50%-16px)] z-10 h-8 w-8 rounded-full bg-white"
          onClick={() => handleScroll("right")}
        >
          <ChevronRightIcon className="min-h-4 min-w-4 text-neutral-900" />
        </ButtonRebrand>
      )}

      <div
        ref={scrollerRef}
        className={cn(
          "relative flex w-full flex-col gap-y-6 overflow-x-scroll px-4 pb-6 transition-transform lg:items-end lg:px-0",
        )}
      >
        {children}
      </div>
    </div>
  );
};

const ComparatorSelectionList = <T,>({
  children,
  className,
}: {
  children: (item: T, index: number) => ReactNode;
  className?: string;
}) => {
  const { mode, selection } = useComparator<T>();

  if (mode === null) {
    return null;
  }

  return (
    <div
      data-selection-list=""
      className={cn(
        "flex w-full min-w-full items-center justify-start gap-x-4 lg:gap-x-8",
        className,
      )}
    >
      {selection.map((item, index) => (
        <Fragment key={index}>{children(item, index)}</Fragment>
      ))}
    </div>
  );
};

const ComparatorSelectedItem = <T,>({
  children,
  className,
  item,
  removeButtonAlignOffset = "middle",
  disableRemove = false,
  ...props
}: ComponentPropsWithoutRef<"div"> & {
  item: T;
  removeButtonAlignOffset?: "top" | "middle" | "bottom";
  disableRemove?: boolean;
}) => {
  const { selectedCount, removeItem } = useComparator<T>();

  return (
    <div
      data-selected-item=""
      className={cn(
        "relative flex flex-col",
        "min-w-[calc(100vw-32px)] max-w-[640px] lg:min-w-[338px] lg:max-w-[338px]",
        className,
      )}
      {...props}
    >
      {children}
      {selectedCount > 2 && !disableRemove && (
        <ButtonRebrand
          onClick={() => removeItem(item)}
          variant="secondary"
          className={cn(
            "absolute right-4 h-12 w-12 rounded-full",
            removeButtonAlignOffset === "top" && "top-0",
            removeButtonAlignOffset === "middle" && "top-[calc(50%-24px)]",
            removeButtonAlignOffset === "bottom" && "bottom-0",
          )}
        >
          <Trash2Icon className="text-black-900 h-5 w-5" />
        </ButtonRebrand>
      )}
    </div>
  );
};

const ComparatorModal = ({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof Dialog> & {
  className?: string;
}) => {
  const { mode, setMode, setSimulatorAmount } = useComparator();

  const handleCloseModal = () => {
    setMode("selection");
    setSimulatorAmount(null);
  };

  if (mode !== "comparison") {
    return null;
  }

  return (
    <Dialog
      {...props}
      open={mode === "comparison"}
      onOpenChange={handleCloseModal}
    >
      <DialogContent
        hasCloseButton={false}
        className={cn(
          "flex h-dvh w-screen flex-col overflow-x-hidden p-0 sm:h-[calc(100dvh-48px)] sm:max-w-[calc(100vw-48px)] sm:rounded-xl",
          className,
        )}
      >
        {children}
        <DialogFooter />
      </DialogContent>
    </Dialog>
  );
};

const ComparatorModalHeader = ({
  children,
  className,
}: ComponentPropsWithoutRef<typeof DialogHeader>) => {
  return (
    <DialogHeader
      className={cn(
        "relative w-full space-y-0 px-4 pt-4 sm:px-8 sm:pt-8 lg:flex-row lg:justify-between",
        className,
      )}
    >
      <DialogClose className="absolute right-6 top-4 sm:right-8 sm:top-8" />
      {children}
    </DialogHeader>
  );
};

const ComparatorModalTitle = ({
  children,
  className,
}: ComponentPropsWithoutRef<typeof DialogHeader>) => {
  const { selectedCount } = useComparator();

  return (
    <DialogTitle
      className={cn(
        "flex items-center gap-4 text-balance text-left",
        className,
      )}
    >
      {children}
      <RebrandBadge className="font-roobert h-6 w-6 whitespace-nowrap rounded-full border-neutral-200 bg-neutral-100 text-xs font-medium text-neutral-900">
        {selectedCount}
      </RebrandBadge>
    </DialogTitle>
  );
};

const ComparatorSimulatorAmount = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "flex w-full flex-col gap-6 border border-[#DFE2E8] bg-neutral-100 p-4 sm:rounded-lg sm:p-6",
      className,
    )}
    {...props}
  >
    {children}
  </div>
));

const ComparatorSimulatorAmountHeader = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"header">
>(({ children, className, ...props }, ref) => (
  <header
    ref={ref}
    className={cn(
      "flex w-full flex-col gap-6 lg:flex-row lg:justify-between",
      className,
    )}
    {...props}
  >
    {children}
  </header>
));

const ComparatorSimulatorAmountTitle = forwardRef<
  HTMLParagraphElement,
  ComponentPropsWithoutRef<"p">
>(({ children, className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn(
      "font-roobert text-center text-lg font-semibold leading-[25px] sm:text-left",
      className,
    )}
    {...props}
  >
    {children}
  </p>
));

const ComparatorSimulatorAmountDescription = forwardRef<
  HTMLParagraphElement,
  ComponentPropsWithoutRef<"p">
>(({ children, className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn(
      "font-roobert text-center text-sm leading-[19.6px] text-neutral-900 sm:text-left",
      className,
    )}
    {...props}
  >
    {children}
  </p>
));

const ComparatorTable = forwardRef<
  ElementRef<typeof Accordion>,
  Omit<ComponentPropsWithoutRef<typeof Accordion>, "type"> & {
    defaultOpen?: boolean;
  }
>(
  (
    { children, className, value, defaultOpen = false, style, onScroll },
    ref,
  ) => (
    <Accordion
      ref={ref}
      type="single"
      collapsible
      className="w-full"
      style={style}
      defaultValue={defaultOpen && value ? value.toString() : ""}
      onScroll={onScroll}
    >
      <AccordionItem
        value={value ? value.toString() : ""}
        className={cn("w-full rounded-none border-0", className)}
      >
        {children}
      </AccordionItem>
    </Accordion>
  ),
);

const ComparatorTableHeader = forwardRef<
  ElementRef<typeof AccordionTrigger>,
  Omit<ComponentPropsWithoutRef<typeof AccordionTrigger>, "className"> & {
    triggerClassName?: string;
  }
>(({ children, triggerClassName, headerClassName, style }, ref) => (
  <AccordionTrigger
    ref={ref}
    headerClassName={cn("relative min-h-10", headerClassName)}
    className={cn(
      "fixed left-0 right-8 lg:left-8",
      "h-10 min-w-40 lg:min-w-[200px]",
      "bg-accent-super-light-rebrand font-roobert text-accent justify-start border px-4 text-xs font-semibold",
      triggerClassName,
    )}
    style={style}
  >
    {children}
  </AccordionTrigger>
));

const ComparatorTableRow = forwardRef<
  ElementRef<typeof AccordionContent>,
  ComponentPropsWithoutRef<typeof AccordionContent>
>(({ children, className, style }, ref) => (
  <AccordionContent
    ref={ref}
    className={cn("flex w-full items-center p-0", className)}
    containerClassName="overflow-visible"
    style={style}
  >
    {children}
  </AccordionContent>
));

const ComparatorTableRowLabel = forwardRef<
  HTMLSpanElement,
  ComponentPropsWithoutRef<"span">
>(({ children, className }, ref) => (
  <span
    ref={ref}
    className={cn(
      "min-w-40 lg:min-w-[200px]",
      "fixed left-0 z-10 pl-4 lg:left-8 lg:pr-4",
      "font-roobert block py-3 text-xs text-neutral-900",
      className,
    )}
  >
    {children}
  </span>
));

const ComparatorTableRowValue = forwardRef<
  HTMLSpanElement,
  ComponentPropsWithoutRef<"span">
>(({ children, className }, ref) => (
  <span
    ref={ref}
    className={cn(
      "min-w-[calc(100vw-160px-32px)] max-w-[640px] last:min-w-[calc(100vw-160px)] lg:min-w-[338px] lg:max-w-[338px] last:lg:min-w-[338px]",
      "ml-40 lg:ml-0 lg:w-full",
      "px-2 py-3 lg:px-4",
      "font-roobert text-black-primary text-xs font-semibold",
      className,
    )}
  >
    {children}
  </span>
));

const formSchema = z
  .object({
    minAmount: z.number(),
    simulatorAmount: z.number(),
  })
  .superRefine((formData, formContext) => {
    if (formData.simulatorAmount < formData.minAmount) {
      formContext.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["simulatorAmount"],
        message: `Le montant minimum est de ${formatCurrency(
          formData.minAmount * 100,
        )}`,
      });
    }
  });

const ComparatorSimulatorAmountForm = ({
  children,
}: PropsWithChildren<{
  min?: number;
}>) => {
  const { config, simulatorAmount, setSimulatorAmount } = useComparator();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      minAmount: config.defaultSimulatorAmount,
      simulatorAmount: simulatorAmount ?? config.defaultSimulatorAmount,
    },
  });

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    setSimulatorAmount(data.simulatorAmount);
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-6 sm:px-8"
      >
        {children}
      </form>
    </FormProvider>
  );
};

const ComparatorSimulatorAmountFormField = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  const form = useFormContext();

  const handleValueChange = (value: string | undefined) => {
    const digitOnlyValue = value?.replace(/\[^\D]/, "");
    form.setValue("simulatorAmount", Number(digitOnlyValue));
  };

  return (
    <div
      ref={ref}
      className={cn(
        "relative flex min-h-[38px] w-full items-start focus-within:gap-1 md:w-auto md:max-w-[596px] md:justify-end",
        className,
      )}
      {...props}
    >
      <CurrencyInputConnected
        name="simulatorAmount"
        onValueChange={handleValueChange}
        className="w-1/2"
        inputClassName="rounded-r-none border-[#DFE2E8]"
      />
      <ButtonRebrand
        type="submit"
        variant="primary-rebrand"
        className="font-roobert w-1/2 rounded-l-none"
      >
        Relancer la simulation
      </ButtonRebrand>
    </div>
  );
});

export {
  ComparatorTrigger,
  ComparatorSelectionTrigger,
  ComparatorModalTrigger,
  ComparatorClear,
  ComparatorSelectableItem,
  ComparatorBanner,
  ComparatorBannerHeader,
  ComparatorBannerTitle,
  ComparatorBannerDescription,
  ComparatorSelectionList,
  ComparatorSelectedItem,
  ComparatorModal,
  ComparatorModalHeader,
  ComparatorModalTitle,
  ComparatorSimulatorAmount,
  ComparatorSimulatorAmountHeader,
  ComparatorSimulatorAmountTitle,
  ComparatorSimulatorAmountDescription,
  ComparatorSimulatorAmountForm,
  ComparatorSimulatorAmountFormField,
  ComparatorSelectionScroller,
  ComparatorTable,
  ComparatorTableHeader,
  ComparatorTableRow,
  ComparatorTableRowLabel,
  ComparatorTableRowValue,
};
