import tailwindContainerQueries from "@tailwindcss/container-queries";
import tailwindCssAnimate from "tailwindcss-animate";
import defaultTheme from "tailwindcss/defaultTheme";
import tailwindCssPlugin from "tailwindcss/plugin";

import { generatedZIndex } from "../utils/z-index";
import themeColors from "./colors.json";
import themeShadows from "./shadows.json";

const tailwindConfig = {
  theme: {
    fontFamily: {
      obviously: ["Obviously", ...defaultTheme.fontFamily.sans],
      montserrat: ["Montserrat", ...defaultTheme.fontFamily.sans],
      brand: ["Obviously", ...defaultTheme.fontFamily.sans],
      sans: ["Montserrat", ...defaultTheme.fontFamily.sans],
      roobert: ["Roobert", ...defaultTheme.fontFamily.sans],
    },
    extend: {
      zIndex: generatedZIndex,
      spacing: {},
      boxShadow: themeShadows,
      colors: themeColors,
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
      // borderRadius: {
      //   md: "8px", // TODO: discuss
      // },
    },
  },
  plugins: [
    tailwindCssAnimate,
    tailwindContainerQueries,
    tailwindCssPlugin(({ addVariant }) => {
      addVariant("search-cancel", "&::-webkit-search-cancel-button");
      addVariant("starting", "@starting-style");
      addVariant("not-last", "&:not(:last-child)");
      addVariant("not-first", "&:not(:first-child)");
    }),
  ],
};

export default tailwindConfig;
