import { useCallback, useState } from "react";
import { PKResult } from "@placekit/client-js";
import placekit from "@placekit/client-js/lite";
import useDebouncedCallback from "@restart/hooks/useDebouncedCallback";

const placeKitApiKey = import.meta.env["VITE_PLACEKIT_API_KEY"] as string;
const pk = placekit(placeKitApiKey);

export interface Location {
  city: string;
  country: string;
}

export function usePlaceSearch() {
  const [results, setResults] = useState<PKResult[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isSelected, setIsSelected] = useState(false);

  const searchPlaces = useDebouncedCallback(async (query: string) => {
    if (query.length === 0 || isSelected) {
      setResults([]);
      setShowResults(false);
      return;
    }

    try {
      const response = await pk.search(query, {
        countries: ["FR"],
        types: ["city"],
      });

      setResults(response.results);
      setShowResults(response.results.length > 0);
      setSelectedIndex(-1);
    } catch (error) {
      console.error("Error searching places:", error);
      setResults([]);
      setShowResults(false);
    }
  }, 300);

  const formatResult = useCallback(
    (result: PKResult): Location => ({
      city: result.name || "",
      country: result.countrycode?.toUpperCase() || "",
    }),
    [],
  );

  return {
    results,
    showResults,
    selectedIndex,
    setSelectedIndex,
    isSelected,
    setIsSelected,
    searchPlaces,
    formatResult,
  };
}
