import React from "react";
import { formatDistanceToNow } from "date-fns";
import { fr } from "date-fns/locale";
import { Link } from "react-router-dom";

import type { Notification } from "@tudigo-monorepo/core-tudigo-api-models";

import { useNotifications } from "../../hooks/use-notification";
import {
  getNotificationLinkConfig,
  getNotificationMessage,
} from "../../utils/notification-utils";

export function NotificationItem(props: { notification: Notification }) {
  const { notification } = props;
  const { markNotificationAsRead, isRead } = useNotifications();
  const linkConfig = getNotificationLinkConfig(notification);

  const formattedTime = isNaN(new Date(props.notification.createdAt).getTime())
    ? "Date inconnue"
    : formatDistanceToNow(new Date(props.notification.createdAt), {
        addSuffix: true,
        locale: fr,
      });

  const handleClick = () => {
    markNotificationAsRead(notification.id);
    if (linkConfig.openInNewTab) {
      window.open(linkConfig.url, "_blank");
    }
  };

  const NotificationContent = (
    <div
      className={`border-light-1 relative flex cursor-pointer items-center justify-between rounded-lg border p-4  ${
        isRead(notification) ? "border-dashed" : " border-solid"
      } hover:border-primary-rebrand hover:bg-accent-super-light-rebrand`}
    >
      <div className="text-black-primary font-roobert flex w-[95%] flex-col gap-1 text-xs">
        <p className="font-normal">{getNotificationMessage(notification)}</p>
        <p className="text-primary-rebrand text-[10px]">{formattedTime}</p>
      </div>

      {!isRead(notification) && (
        <span className="bg-primary-rebrand absolute right-4 top-4 h-1.5 w-1.5 rounded-full" />
      )}
    </div>
  );

  if (linkConfig.openInNewTab) {
    return (
      <div onClick={handleClick} className="cursor-pointer no-underline">
        {NotificationContent}
      </div>
    );
  }

  return (
    <Link
      to={linkConfig.url}
      onClick={handleClick}
      className="cursor-pointer no-underline"
    >
      {NotificationContent}
    </Link>
  );
}
