import React, { ReactNode } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { BankSelector } from "@tudigo-monorepo/web-feature-organizations";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  ErrorMessage,
  PhoneInputConnected,
  SelectInputConnected,
  Separator,
  TextInputConnected,
} from "@tudigo-monorepo/web-tudigo-components";

const formSchema = z.object({
  type: z.string().min(1, "Veillez selectionner un type de compte"),
  bank: z.any().refine((val) => Boolean(val), {
    message: "Veillez selectionner un établissement bancaire",
  }),
  bankContactInformation: z.any(),
  accountNumber: z.string().min(1, "Veillez entrer un numéro de compte"),
  iban: z.string().min(1, "Veillez entrer un IBAN"),
  bic: z.string().min(1, "Veillez entrer un BIC"),
});

const defaultValuesShape = {
  type: "",
  bank: null,
  bankContactInformation: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
  accountNumber: "",
  iban: "",
  bic: "",
};

export type PeaPmeFormSchema = z.infer<typeof formSchema>;

export const PeaPmeForm = ({
  onSubmit,
  children,
  defaultValues = defaultValuesShape,
}: {
  onSubmit: (data: PeaPmeFormSchema) => void;
  children: ReactNode;
  defaultValues?: Partial<PeaPmeFormSchema>;
}) => {
  const methods = useForm<PeaPmeFormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="mb-8 max-w-xs">
          <SelectInputConnected
            name="type"
            label="Type de compte"
            placeholder="Select an account type"
            disabled={defaultValues?.type !== ""}
            required
            options={[
              { value: "pea", label: "PEA" },
              { value: "pea_pme", label: "PEA-PME" },
            ]}
          />
        </div>

        <Separator className="my-6" />

        <fieldset className="mb-6 max-w-xs">
          <legend className="text-dark-2 mb-3 text-sm ">
            Informations bancaires
          </legend>

          <div className="flex flex-col gap-4">
            <BankSelector
              required
              allBanksOption
              label="Etablissement bancaire"
              placeholder="Sélectionner un établissement"
              contentClassName="md:min-w-[420px]"
              onBankChange={(bank) => methods.setValue("bank", bank)}
              selectedBank={methods.watch("bank")}
            />
            {methods?.formState?.errors?.bank?.message && (
              <ErrorMessage className="-mt-1">
                {String(methods?.formState?.errors?.bank?.message)}
              </ErrorMessage>
            )}

            <TextInputConnected
              required
              name="accountNumber"
              label="Numero du compte"
              placeholder="00000000000"
            />

            <TextInputConnected
              required
              name="iban"
              label="IBAN du compte"
              placeholder="FR00 0000 0000 0000 0000 0000 000"
            />

            <TextInputConnected
              required
              name="bic"
              label="BIC"
              placeholder="00000XXX"
            />
          </div>
        </fieldset>

        <Accordion type="single" collapsible>
          <AccordionItem value="conseillerBancaire">
            <AccordionTrigger className="text-dark-2 text-left text-sm">
              <div>
                <h3 className="mb-1 font-medium">Votre conseiller bancaire</h3>
                <p>
                  Si vous connaissez les informations concernant votre
                  conseiller bancaire, veuillez compléter tous les champs.
                </p>
              </div>
            </AccordionTrigger>
            <AccordionContent className="px-3 py-2">
              <fieldset className="flex max-w-xs flex-col gap-3">
                <TextInputConnected
                  name="bankContactInformation.firstName"
                  label="Prénom"
                  placeholder="Entrer le prénom"
                />
                <TextInputConnected
                  name="bankContactInformation.lastName"
                  label="Nom"
                  placeholder="Entrer le nom"
                />
                <TextInputConnected
                  name="bankContactInformation.email"
                  label="Email"
                  placeholder="Entrer l'email"
                />
                <PhoneInputConnected
                  name="bankContactInformation.phone"
                  label="Téléphone"
                />
              </fieldset>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        {children}
      </form>
    </FormProvider>
  );
};
