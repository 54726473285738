import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import resolveConfig from "tailwindcss/resolveConfig";
import { useMediaQuery } from "usehooks-ts";

import tailwindConfig from "../config/tailwind-config";

export function cn(...args: ClassValue[]) {
  return twMerge(clsx(args));
}

export function getMediaQueries() {
  const { theme } = resolveConfig(tailwindConfig as any);

  return theme?.["screens"];
}

export function useTwMediaQuery(screen: "sm" | "md" | "lg" | "xl" | "2xl") {
  const mediaQueries = getMediaQueries();

  return useMediaQuery(`(max-width: ${mediaQueries[screen]})`);
}
