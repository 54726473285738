import { useFormContext } from "react-hook-form";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/form";
import { InputConnectedProps } from "../../components/input.type";
import { CurrencyInputV2, type CurrencyInputV2Props } from "./currency-input";

export function CurrencyInputConnected({
  name,
  label,
  placeholder,
  className,
  inputClassName,
  description,
  required,
  ...inputProps
}: InputConnectedProps & CurrencyInputV2Props) {
  const form = useFormContext();

  if (!form) {
    throw new Error("InputConnected must be used within a Form component");
  }

  return (
    <FormField
      control={form?.control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <FormItem className={className}>
            {label && <FormLabel required={required}>{label}</FormLabel>}
            <FormControl>
              <CurrencyInputV2
                {...field}
                className={cn(inputClassName, {
                  "border-error": Boolean(fieldState?.error),
                })}
                placeholder={placeholder}
                onValueChange={(value) => {
                  field.onChange(value);
                }}
                {...inputProps}
              />
            </FormControl>

            {fieldState?.error && <FormMessage />}

            {Boolean(!fieldState?.error && description) && (
              <FormDescription>{description}</FormDescription>
            )}
          </FormItem>
        );
      }}
    />
  );
}
