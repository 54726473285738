"use client";

import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDown } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn("border-light-2 rounded-md border", className)}
    {...props}
  />
));

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    headerClassName?: string;
  }
>(({ className, children, headerClassName, ...props }, ref) => (
  <AccordionPrimitive.Header className={cn("flex", headerClassName)}>
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        `
        border-light-2 flex flex-1 items-center justify-between border-b
        px-3 py-2 transition-all
        [&[data-state=closed]]:border-b-0 [&[data-state=open]>svg]:rotate-180
        `,
        className,
      )}
      {...props}
    >
      {children}
      <ChevronDown className="ml-2 h-4 w-4 shrink-0 transition-transform duration-200" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content> & {
    containerClassName?: string;
  }
>(({ className, containerClassName, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      `
    data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down
    overflow-hidden text-sm
    transition-all
    `,
      containerClassName,
    )}
    {...props}
  >
    <div className={cn("pb-4 pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
));

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
