import { TudigoAPI } from "@tudigo-monorepo/core-tudigo-api";

// TODO transform it to apiClient directly
export const getApiClient = () => {
  const tudigoAPI = new TudigoAPI({
    baseURL: `${import.meta.env["VITE_API_URL"]}`,
  });

  return tudigoAPI;
};

export type CollectionRequestParams<P, F> = {
  pagination?: { offset?: number; limit?: number };
  filters?: F;
  fields?: string;
  pathParams?: P;
};

export * from "./lib/users.queries";
export * from "./lib/user-repayments.queries";
export * from "./lib/investments.queries";
export * from "./lib/user-organizations.queries";
export * from "./lib/projects.queries";
export * from "./lib/project-categories.queries";
export * from "./lib/project-tags.queries";
export * from "./lib/project-maturities.queries";
export * from "./lib/assemblies.queries";
export * from "./lib/configuration.queries";
export * from "./lib/query-key-factory";
export * from "./lib/consents.queries";

// queries
export * from "./lib/queries/assemblies/get-assemblies";
export * from "./lib/queries/assemblies/get-assembly-participants-by-organization";
export * from "./lib/queries/banks/get-banks";
export * from "./lib/queries/clubs/get-club-by-id";
export * from "./lib/queries/clubs/get-clubs";
export * from "./lib/queries/circles/get-circle-member-by-id";
export * from "./lib/queries/clubs/get-user-club-members";
export * from "./lib/queries/clubs/get-club-members";
export * from "./lib/queries/clubs/get-territories";
export * from "./lib/queries/clubs/get-industries";
export * from "./lib/queries/clubs/get-mentorings";
export * from "./lib/queries/clubs/get-leader-specialities";
export * from "./lib/queries/comments/use-get-comments-by-project-id";
export * from "./lib/queries/comments/use-get-comments-by-club-id";
export * from "./lib/queries/forms/use-get-form-by-id";
export * from "./lib/queries/investments/get-investments";
export * from "./lib/queries/investments/get-investment-by-id";
export * from "./lib/queries/investments/get-investment-feed-by-investment-id";
export * from "./lib/queries/investments/get-user-investments";
export * from "./lib/queries/investor-documents/use-get-investor-documents";
export * from "./lib/queries/investor-profiles/use-get-current-investor-profile-by-user-id";
export * from "./lib/queries/organization-equity-saving-plans/get-organization-equity-saving-plans";
export * from "./lib/queries/organization-members/get-organization-members";
export * from "./lib/queries/organization-persons/use-create-organization-person";
export * from "./lib/queries/organization-persons/use-update-organization-person";
export * from "./lib/queries/organizations/get-organizations";
export * from "./lib/queries/organizations/get-organizations";
export * from "./lib/queries/projects/use-get-project-by-id";
export * from "./lib/queries/repayments/use-get-creditors-repayments-for-debtor-repayment";
export * from "./lib/queries/repayments/use-get-creditors-repayments-periods-for-investment";
export * from "./lib/queries/repayments/use-get-project-debtor-repayments-periods";
export * from "./lib/queries/repayments/use-get-project-debtor-repayments-periods";
export * from "./lib/queries/signature/use-get-signature-request-sync";
export * from "./lib/queries/simulator/use-get-simulator-by-id";
export * from "./lib/queries/user-tax-exemptions/use-get-user-tax-exemptions";
export * from "./lib/queries/users/get-users";
export * from "./lib/queries/user-todos/get-user-todos";
export * from "./lib/queries/wma-customers/get-wma-customer-by-id";
export * from "./lib/queries/wma-customers/get-wma-customers-by-wma-user-id";
export * from "./lib/queries/wma-customers/get-wma-customers-invitations";
export * from "./lib/queries/wma-investments/get-wma-investments";
export * from "./lib/queries/wma-investments/get-wma-investment-by-id";
export * from "./lib/queries/assemblies/get-assembly-participants";
export * from "./lib/queries/webflow-collection/use-get-webflow-collection-by-id";
export * from "./lib/queries/shareholders/get-shareholders";
export * from "./lib/queries/shareholders/get-shareholder-by-id";
export * from "./lib/queries/investments/get-investment-status-changes";
export * from "./lib/queries/circles/get-circle-by-id";
export * from "./lib/queries/circles/get-circles";
export * from "./lib/queries/circles/get-circles-members";
export * from "./lib/queries/circles/get-subscription-periods";
export * from "./lib/queries/circles/get-circle-subscription-periods";
export * from "./lib/queries/circles/get-circle-investments";
export * from "./lib/queries/reportings/get-reportings";
export * from "./lib/queries/reportings/get-organization-reportings";
export * from "./lib/queries/reportings/get-reporting-by-id";
export * from "./lib/queries/reportings/get-analysts";
export * from "./lib/queries/notifications/get-notifications";

// mutations
export * from "./lib/mutations/clubs/use-subscribe-club";
export * from "./lib/mutations/clubs/use-subscription-request-sync";
export * from "./lib/mutations/clubs/use-unsubscribe-club-members";
export * from "./lib/mutations/comments/use-create-comment";
export * from "./lib/mutations/investments/create-cancel-investment-request";
export * from "./lib/mutations/investments/create-bs-regeneration-investment-request";
export * from "./lib/mutations/investments/create-investment-export";
export * from "./lib/mutations/investments/create-investment";
export * from "./lib/mutations/investments/retry-investment-transfer-request";
export * from "./lib/mutations/investments/send-investment-document-signature-link";
export * from "./lib/mutations/investments/pay-investment-with-wallet-balance";
export * from "./lib/mutations/investments/generate-fees-payment-url";
export * from "./lib/mutations/investor-profiles/use-update-investor-profile";
export * from "./lib/mutations/kyc/use-post-kyc-submit-data-request-mutation";
export * from "./lib/mutations/leads/subscribe-project-notifications";
export * from "./lib/mutations/leads/unsubscribe-project-notifications";
export * from "./lib/mutations/organization-bank-accounts/use-create-organization-bank-account";
export * from "./lib/mutations/organization-documents/use-create-organization-document";
export * from "./lib/mutations/organization-documents/use-delete-organization-document";
export * from "./lib/mutations/organization-equity-saving-plan/create-organization-equity-saving-plan";
export * from "./lib/mutations/organization-members/use-invite-organization-member";
export * from "./lib/mutations/organization-person-documents/use-create-organization-person-document";
export * from "./lib/mutations/organization-person-documents/use-delete-organization-person-document";
export * from "./lib/mutations/organization/create-organization";
export * from "./lib/mutations/organization/use-patch-organization";
export * from "./lib/mutations/project-holder-profiles/create-project-holder-profile";
export * from "./lib/mutations/project-holder-profiles/delete-project-holder-profile";
export * from "./lib/mutations/projects/create-project-news";
export * from "./lib/mutations/projects/update-project-news";
export * from "./lib/mutations/projects/delete-project-news";
export * from "./lib/mutations/projects/generate-empty-subscription-form";
export * from "./lib/mutations/projects/post-enable-pea-portfolios-request";
export * from "./lib/mutations/projects/post-project-transition-status";
export * from "./lib/mutations/projects/update-project";
export * from "./lib/mutations/projects/patch-project";
export * from "./lib/mutations/repayments/approve-debtor-repayment";
export * from "./lib/mutations/repayments/create-debtor-repayment";
export * from "./lib/mutations/repayments/delete-debtor-repayment";
export * from "./lib/mutations/user-tax-exemptions/create-user-tax-exemption";
export * from "./lib/mutations/users/delete-user";
export * from "./lib/mutations/users/grant-admin-role";
export * from "./lib/mutations/users/mark-intro-has-done-user";
export * from "./lib/mutations/users/remove-admin-role";
export * from "./lib/mutations/users/update-user";
export * from "./lib/mutations/wma-customers/create-wma-customer";
export * from "./lib/mutations/wma-customers/send-wma-customer-qualification-link";
export * from "./lib/mutations/wma-customers/send-wma-customer-invitation-link";
export * from "./lib/mutations/wma-customers/update-wma-customer-invitation";
export * from "./lib/mutations/wma-profiles/create-wma-profile";
export * from "./lib/mutations/wma-profiles/delete-wma-profile";
export * from "./lib/mutations/leads/subscribe-project-notifications";
export * from "./lib/mutations/leads/unsubscribe-project-notifications";
export * from "./lib/mutations/projects/generate-project-income-tax-certificates";
export * from "./lib/mutations/assemblies/create-assembly";
export * from "./lib/mutations/assemblies/patch-assembly";
export * from "./lib/mutations/assemblies/publish-assembly";
export * from "./lib/mutations/assemblies/close-assembly";
export * from "./lib/mutations/assemblies/send-assembly-vote-reminder";
export * from "./lib/mutations/projects/generate-project-title-deeds";
export * from "./lib/mutations/projects/download-project-title-deeds";
export * from "./lib/mutations/projects/download-project-subscription-forms";
export * from "./lib/mutations/users/request-change-user-email";
export * from "./lib/mutations/users/request-change-user-password";
export * from "./lib/mutations/investments/update-investment";
export * from "./lib/mutations/wma-investments/send-wma-investment-folder-email";
export * from "./lib/mutations/clubs/use-create-circle-member";
export * from "./lib/mutations/clubs/use-update-circle-member";
export * from "./lib/mutations/clubs/use-publish-circle";
export * from "./lib/mutations/investments/generate-investment-account-certificate";
export * from "./lib/mutations/reportings/use-update-reporting";
export * from "./lib/mutations/reportings/use-publish-reporting";
export * from "./lib/mutations/reportings/use-unpublish-reporting";
export * from "./lib/mutations/reportings/use-send-reporting-reminder";
export * from "./lib/mutations/reportings/use-publish-reporting";
export * from "./lib/mutations/reportings/use-unpublish-reporting";
export * from "./lib/mutations/reportings/use-update-reporting-settings";
export * from "./lib/mutations/todos/mark-todo-as-done";
export * from "./lib/mutations/notifications/mark-notification-as-read";
export * from "./lib/mutations/notifications/mark-all-notifications-as-read";
export * from "./lib/mutations/repayments/dunning-all-repayment";
